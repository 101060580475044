<template>
	<div class="header py-2">
		<div class="d-flex flex-row justify-content-between align-items-center">
			<bread-crumb/>			
			<slot>
			</slot>
		</div>
	</div>
</template>

<script>
import BreadCrumb from '@/components/layouts/LayoutDefaultBreadCrumb.vue';

export default {
	props: {
		icone: {
			required: false,
			default: 'fa-home',
			type: String
		},
		titulo: {
			required: true
		},
		subtitulo: {
			required: false
		}
	},
	components: {
		BreadCrumb
	}
}
</script>
