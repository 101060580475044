<template>
    <div>
        <div class="alert alert-dismissible fade show alert-white rounded" role="alert" v-show="status !== 0" 
            :class="[
                        {'alert-success': status == 200 && modo !== 'deletar'},
                        {'alert-success': status == 201},
                        {'alert-info': status == 200 && modo === 'deletar'},
                        {'alert-danger': status == 400},
                        {'alert-danger': status == 401},
                        {'alert-danger': status == 404},
                        {'alert-danger': status == 405},
                        {'alert-danger': status == 409},
                        {'alert-danger': status == 422},
                        {'alert-danger': status == 500}                        
                    ]">
            <div class="icon">
                <i class="fa" :class="[
                        {'fa-check': status == 200 && modo !== 'deletar'},
                        {'fa-check': status == 201},
                        {'fa-info-circle': status == 200 && modo === 'deletar'},
                        {'fa-times-circle': status == 400},
                        {'fa-info-circle': status == 401},
                        {'fa-times-circle': status == 404},
                        {'fa-times-circle': status == 405},
                        {'fa-times-circle': status == 409},
                        {'fa-times-circle': status == 422},
                        {'fa-times-circle': status == 500}
                    ]"></i>
            </div>
            <strong v-if="status === 400 || status === 401 || status === 404 || status === 405 || status === 409 || status === 422 || status === 500" class="alert-heading">
                Houve um ou mais erros ao tentar <i v-if="modo === 'novo'">Incluir</i><i v-if="modo === 'editar'">Alterar</i><i v-if="modo === 'deletar'">Excluir</i> o Registro
                </strong>
            <strong v-if="status === 200 || status === 201" class="alert-heading">
                Registro <i v-if="modo === 'novo'">Incluido</i><i v-if="modo === 'editar'">Alterado</i><i v-if="modo === 'deletar'">Excluido</i> com Sucesso!!!</strong>
            <strong v-if="typeof errors === 'object'" v-for="(erro,key) in errors" :key="key">
                <small v-if="typeof erro === 'object'" v-for="(e,key) in erro" :key="key" style="display: block;">* {{ e }}</small>
            </strong>
            <small v-if="typeof errors !== 'object'" style="display: block;">* {{ errors }}</small>
            <small style="display: block;" v-if="status === (200||201)">* {{ mensagem }}</small>

            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>   


    <!--<div class="container bootstrap snippet">
        <div class="alert alert-success alert-white rounded">
            <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
            <div class="icon">
                <i class="fa fa-check"></i>
            </div>
            <strong>Success!</strong> 
            Changes has been saved successfully!
        </div>
        <div class="alert alert-info alert-white rounded">
            <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
            <div class="icon">
                <i class="fa fa-info-circle"></i>
            </div>
            <strong>Success!</strong> 
            Changes has been saved successfully!
        </div>  
        <div class="alert alert-warning alert-white rounded">
            <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
            <div class="icon">
                <i class="fa fa-warning"></i>
            </div>
            <strong>Success!</strong> 
            Changes has been saved successfully!
        </div>     
        <div class="alert alert-danger alert-white rounded">
            <button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>
            <div class="icon">
                <i class="fa fa-times-circle"></i>
            </div>
            <strong>Success!</strong> 
            Changes has been saved successfully!
        </div>    
    </div>-->

    </div>      
</template>

<script>
export default {
    props: ['status', 'mensagem', 'errors', 'modo']
  
}
</script>

<style scoped>

.alert {
    margin-top:15px;
}

.alert {
  border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: 0 1px 2px rgba(0,0,0,0.11);
  display: table;
  width: 100%;
}

.alert-white {
  background-image: linear-gradient(to bottom, #fff, #f9f9f9);
  border-top-color: #d8d8d8;
  border-bottom-color: #bdbdbd;
  border-left-color: #cacaca;
  border-right-color: #cacaca;
  color: #404040;
  padding-left: 61px;
  position: relative;
}

.alert-white.rounded {
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

.alert-white.rounded .icon {
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
}

.alert-white .icon {
  text-align: center;
  width: 45px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #bdbdbd;
  padding-top: 15px;
}


.alert-white .icon:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #bdbdbd;
  position: absolute;
  border-left: 0;
  border-bottom: 0;
  top: 50%;
  right: -6px;
  margin-top: -3px;
  background: #fff;
}

.alert-white .icon i {
  font-size: 20px;
  color: #fff;
  left: 12px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}
/*============ colors ========*/
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-white.alert-success .icon, 
.alert-white.alert-success .icon:after {
  border-color: #54a754;
  background: #60c060;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #98cce6;
  color: #3a87ad;
}

.alert-white.alert-info .icon, 
.alert-white.alert-info .icon:after {
  border-color: #3a8ace;
  background: #4d90fd;
}


.alert-white.alert-warning .icon, 
.alert-white.alert-warning .icon:after {
  border-color: #d68000;
  background: #fc9700;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #f1daab;
  color: #c09853;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #e0b1b8;
  color: #b94a48;
}

.alert-white.alert-danger .icon, 
.alert-white.alert-danger .icon:after {
  border-color: #ca452e;
  background: #da4932;
}

</style>
