<template>
    <div class="mct-form container">
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0" v-if="withAlertas == 'true'"></mct-alertas>
        <div class="container-fluid position-relative">
            <mct-form>
                <slot name="formulario"></slot>    
            </mct-form>
        </div>
        <mct-form-botoes @submit="submit()" :modo="modoComp" v-if="withBotoes == 'true'">
            <slot name="botoes"></slot>
        </mct-form-botoes>
    </div>
</template>

<script>

import MctTopoLista from './MctTopoLista.vue'
import MctForm from './MctForm.vue'
import MctFormBotoes from './MctFormBotoes.vue'
import MctAlertas from './MctAlertas.vue' 
import MctMixinsForm from '../funcoes/MctMixinsForm'

export default {
    Name: 'FormPadraoSB', 
    mixins: [MctMixinsForm], 
    components: {
        MctForm,
        MctFormBotoes,
        MctTopoLista,
        MctAlertas,
        },
    props: ['source', 'titulo', 'modo', 'listaAdicional', 'withAlertas', 'withBotoes'],
    data () {
        return{
            
        }
    },
    methods: {
    },
  
}
</script>