<template>
  <b-breadcrumb class="m-0 p-2 pl-0 bg-light">
    <b-breadcrumb-item to="/">
      <i class="fa fa-home text-primary"></i>
    </b-breadcrumb-item>
    <template v-for="(item,idx) in itens">
      <b-breadcrumb-item :key="idx" active v-if="(typeof item.to == 'undefined')" >{{item.text}}</b-breadcrumb-item>
      <b-breadcrumb-item :key="idx" :to="item.to" v-else>{{item.text}}</b-breadcrumb-item>
    </template>
  </b-breadcrumb>  
</template>

<script>
export default {
  name: 'LayoutDefaultBreadCrumb',
  data () {
    return {
      itens: []
    }
  },
  mounted () { this.updateList() },
  watch: { 
    '$route' () { 
      this.updateList() 
    } 
  },
  methods: {
    updateList () { 
      this.itens = this.$route.meta.breadcrumb 
    }
  }
}
</script>