import Vue from 'vue'
import axios from '../../http'
import { mapState } from 'vuex'

export default {
    data () {
        return {
            columns: {},
            lista: {},
            message: '',
            errors: {} ,
            status: 0,
            chamaModal: {},
            gravou: false,   
            titAlerta: '',
            msgAlerta: '',
            tipoAlerta: '',
            mode: '',
            isValidated: true,
        }
    },
    methods: {
        async getData() {
            let URL = ''
            let Parms = '?'
            if (Object.keys(this.$route.params).length > 1){
              for (var index in this.$route.params){
                if (Parms === '?'){
                  Parms = Parms + index +'='+ this.$route.params[index]
                }else{
                  Parms = Parms + '&' + index +'='+ this.$route.params[index]
                  
                }
              }
            }else{
                  Parms = '/' + this.$route.params['codigo']
            }
    
            if (typeof this.source != 'undefined'){
              if (this.modo !== 'novo'){
                URL = this.source + Parms
              }else{
                URL = this.source
              }         

            await axios.get(`${URL}`)
                .then(response => {
                  this.lista = response.data.data
                  this.columns = response.data.columns
                  this.errors = {}
                  if (this.modo == 'novo'){
                    this.onNovo()
                  } else if (this.modo == 'editar'){
                    this.onAfterEditar()
                  }  
                  
                  this.setData()
                })
                .catch(response => {
                  this.message = response.response.data.message
                  this.errors = response.response.data.errors
                  this.status = response.response.status
                })
            }
            this.onAfterData()
        },
        async onValida(){
            //this.isValidated = true
            //console.log('Mixim Valida')
        },
        onBeforeSave() {
            
        },
       async submit() {
            if (this.modo === 'novo'){
                await this.onValida()

                if (this.isValidated) {
                    this.onSalvar()
                }
            } else
                if (this.modo === 'editar'){
                    await this.onValida()

                    if (this.isValidated) {
                        this.onEditar()
                    }
                } else
                    if (this.modo === 'deletar'){
                        if (await this.onBeforeDeletar()){
                            this.$swal({
                                title: 'Deseja realmente deletar o registro?',
                                text: "Este evento não podera ser revertido!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: 'Sim'
                                }).then((result) => {
                                    if (result.value) {
                                        this.onDeletar()   
                                    }                     
                                })
                        }
                        /*this.titAlerta = 'Confirme' 
                        this.msgAlerta = 'Deseja realmente excluir o registro?'
                        this.tipoAlerta = this.modo 
                        $('#MctPopUp').modal('show')*/
                    } else
                    if (this.modo === 'cancelar'){
                        if (await this.onBeforeCancelar()){
                            this.$swal({
                                title: 'Deseja realmente cancelar o registro?',
                                text: "Este evento irá cancelar o registro!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: 'Sim'
                                }).then((result) => {
                                    if (result.value) {
                                        this.onCancelar()   
                                    }                     
                                })
                        }
                    }
            if ((this.modo === 'novo') || (this.modo === 'editar')) this.onAfterNovo();                     
        },
        onAfterData(){
            /* */
        },
        onAfterNovo(){
            /* */
        },     
        onAfterEditar(){
            /* */
        },
        async onBeforeDeletar(){
            /* */
            return await true;
        },
        async onBeforeCancelar(){
            /* */
            return await true;
        },

        onNovo () {
            for (var i = 0; i < this.columns.length; i++) {          
                if (this.columns[i]['valor_padrao'] && this.columns[i]['valor_padrao'] !=''){
                    if (this.columns[i]['valor_padrao'] == 'mct_exercicio'){
                    this.lista[this.columns[i]['nome']] = this.Exercicio
                    } else
                    if (this.columns[i]['valor_padrao'] == 'mct_entidade'){
                        this.lista[this.columns[i]['nome']] = this.Entidade
                        }else
                    if (this.columns[i]['valor_padrao'] == 'mct_competencia'){
                        this.lista[this.columns[i]['nome']] = this.Competencia
                        }else
                    if (this.columns[i]['valor_padrao'] == 'mct_orcamento'){
                    this.lista[this.columns[i]['nome']] = this.EntidadeOrc
                        }else
                    if (this.columns[i]['valor_padrao'] == 'mct_escola'){
                        this.lista[this.columns[i]['nome']] = this.Escola
                        }else
                    if (this.columns[i]['valor_padrao'] == 'mct_ano_letivo'){
                        this.lista[this.columns[i]['nome']] = this.Exercicio
                        }else
                        this.lista[this.columns[i]['nome']] = this.columns[i]['valor_padrao']
                }
            }
        },        
        onConfirma (){
            if (this.modo === 'deletar'){
                this.onDeletar()
            }
        },

        async onSalvar() {
            
            var dados = this.lista
            this.onBeforeSave()
            await axios.post(`${this.source}`,dados)
                .then(response => {
                    this.message = response.data.message
                    this.status = response.status
                    this.lista = response.data.data
                    this.errors = {}
                    //this.setData()
                    
                    this.$swal('Sucesso!',
                    'Registro incluído com sucesso!',
                    'success');  
                //this.mode = 'readonly'                                          
                this.$router.back() 
                })
                .catch(response => {
                   this.message = response.response.data.message
                    if (response.response.data.error){
                       this.errors = response.response.data.error.errorInfo[2]              
                    }else{
                       this.errors = response.response.data.errors
                    }
                   this.status = response.response.status
                   //this.setData()
                   
                }) 
        },
        onBeforeSave(){
            //this.sourceForm = this.source 
        },
        onAfterSave(){
            //this.$emit('setAfterSave', this.lista)
        },
        async onEditar() {
            var dados = this.lista
            this.onBeforeSave()
            let Parms = '?'
            if (Object.keys(this.$route.params).length > 1){
                for (var index in this.$route.params){
                    if (Parms === '?'){
                        Parms = Parms + index +'='+ this.$route.params[index]
                    }else{
                        Parms = Parms + '&' + index +'='+ this.$route.params[index]                        
                    }
                }
            }else{
                Parms = '/' + this.$route.params['codigo']
            }

            await axios.put(`${this.source}${Parms}`,dados)
                .then(response => {
                    this.message = response.data.message
                    this.status = response.status
                    this.lista = response.data.data
                    this.errors = {}
                    //this.mode = 'readonly'                                          
                    //this.setData()
                    this.$swal('Sucesso!',
                                'Registro alterado com sucesso!',
                                'success');
                    this.$router.back()                                  
                })
                .catch(response => {
                    this.message = response.response.data.message
                    if (response.response.data.error){
                       this.errors = response.response.data.error.errorInfo[2]              
                    }else{
                       this.errors = response.response.data.errors
                    }
                    this.status = response.response.status
                    //this.setData()
                })          
        },
        async onCancelar() {
            var dados = this.lista
            this.onBeforeSave()
            let Parms = '?'
            if (Object.keys(this.$route.params).length > 1){
                for (var index in this.$route.params){
                    if (Parms === '?'){
                        Parms = Parms + index +'='+ this.$route.params[index]
                    }else{
                        Parms = Parms + '&' + index +'='+ this.$route.params[index]                        
                    }
                }
            }else{
                Parms = '/' + this.$route.params['codigo']
            }

            await axios.put(`${this.source}${Parms}`,dados)
                .then(response => {
                    this.message = response.data.message
                    this.status = response.status
                    this.lista = response.data.data
                    this.errors = {}
                    //this.mode = 'readonly'                                          
                    //this.setData()
                    this.$swal('Sucesso!',
                                'Registro cancelado com sucesso!',
                                'success');
                    this.$router.back()                                  
                })
                .catch(response => {
                    this.message = response.response.data.message
                    if (response.response.data.error){
                       this.errors = response.response.data.error.errorInfo[2]              
                    }else{
                       this.errors = response.response.data.errors
                    }
                    this.status = response.response.status
                    //this.setData()
                })          
        },
        
        async onDeletar() {
            let Parms = '?'
            if (Object.keys(this.$route.params).length > 1){
                for (var index in this.$route.params){
                    if (Parms === '?'){
                        Parms = Parms + index +'='+ this.$route.params[index]
                    }else{
                        Parms = Parms + '&' + index +'='+ this.$route.params[index]                    
                    }
                }
            }else{
                Parms = '/' + this.$route.params['codigo']
            }
            await axios.delete(`${this.source}${Parms}`)
                .then(response => {
                    this.message = response.data.message
                    this.errors = {}                    
                    this.status = response.status
                    //this.mode = 'readonly'
                    if (response.statusText == 'OK') {
                        this.$swal(
                            'Deletado!',
                            'O registro foi deletado com sucesso.',
                            'success'
                        )
                    }
                    this.$router.back()                     
                })
                .catch(response => {
                    var mgsErro = '';
                    this.message = response.message
                    if (response.response.data.error){
                        this.errors = response.response.data.error.errorInfo[2]              
                        mgsErro = this.errors;
                     }else{
                        this.errors = response.response.data.errors
                        for (var item in this.errors) {
                            mgsErro = this.errors[item].map((item2) => {
                                return item2 + '\n';
                            })    
                        }
                    }                     

                    this.status = response.response.status

                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) Excluir o Registro',
                        text: mgsErro,
                        footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                    })                    
                })
        },

        setData (){
            this.$emit('setData', this.lista, this.columns, this.mode, this.errors)
        }        
    },
    mounted() {
        setTimeout(() => {
          
        }, 1000)
    },
    computed: {
        modoComp (){
            //if (this.gravou){
                if (this.mode === 'readonly'){
                    return 'visualizar'
                }else{
                    return this.modo
                }
            },
            ...mapState('Login',['infoLogado','infoModulo','infoSubModulo','Entidade','Exercicio','Competencia','EntidadeOrc','Escola'])       
        }, 
        created () {
            this.getData()
    }           
}